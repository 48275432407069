import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import { setFullScreenHeight } from '../store/actions/settings'

const mapDispatchToProps = dispatch => {
  return {
    setFullScreenHeight: size => dispatch(setFullScreenHeight(size))
  }
}

const FullscreenListener = React.memo(({ fullScreenApp, handleCloseFullScreen }) => {
  const [isFullscreen, setIsFullscreen] = useState(false)
  const [passInitialState, setPassInitialSTate] = useState(false)

  const handleFullscreenChange = () => {
    setIsFullscreen(
      document.fullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement ||
        document.msFullscreenElement ||
        document.fullscreen ||
        document.mozFullScreen ||
        document.webkitIsFullScreene ||
        document.fullScreenMode
    )
  }

  const handleToggle = () => {
    const el = document.documentElement
    if (!isFullscreen) {
      setPassInitialSTate(true)
      if (el.requestFullscreen) {
        el.requestFullscreen()
      } else if (el.mozRequestFullScreen) {
        el.mozRequestFullScreen()
      } else if (el.webkitRequestFullscreen) {
        el.webkitRequestFullscreen()
      } else if (el.msRequestFullscreen) {
        el.msRequestFullscreen()
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      }
    }
  }
  useEffect(() => {
    setFullScreenHeight(window.innerHeight)
    if (!isFullscreen && fullScreenApp && passInitialState) {
      handleCloseFullScreen()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFullscreen])

  useEffect(() => {
    handleToggle()
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange)
    document.addEventListener('mozfullscreenchange', handleFullscreenChange)
    document.addEventListener('msfullscreenchange', handleFullscreenChange)
    document.addEventListener('MSFullscreenChange', handleFullscreenChange) //IE11
    document.addEventListener('fullscreenchange', handleFullscreenChange)

    return () => {
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange)
      document.removeEventListener('mozfullscreenchange', handleFullscreenChange)
      document.removeEventListener('msfullscreenchange', handleFullscreenChange)
      document.removeEventListener('MSFullscreenChange', handleFullscreenChange)
      document.removeEventListener('fullscreenchange', handleFullscreenChange)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
})

export default connect(null, mapDispatchToProps)(FullscreenListener)
