import React, { useState } from 'react'
import { AppInstances } from '../../utils/counterSdkInstance'

import Modal from '@material-ui/core/Modal'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'

import './Resume.css'

const Resume = props => {
  const [customer, setCustomer] = useState('')

  const handleClose = showNotification => {
    props.handleCloseModal(showNotification)
  }

  const sendEmail = () => {
    if (customer.length <= 0) {
      setCustomer(props.customer)
    }
    AppInstances.getCountrSdk().then(socket => {
      socket.ws.transactions.readOne
        .send(
          props.transaction._id,
          JSON.stringify({
            type: 'email',
            mailing_list_permission: true,
            value: customer
          })
        )
        .then(transaction => {
          setCustomer('')
          handleClose(true)
        })
    })
  }

  return (
    <Modal
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'
      open={props.open}
      onClose={handleClose}
    >
      <div className='modal'>
        <Grid container alignItems='center' justifyContent='center'>
          <Grid item xs={12}>
            <Typography variant='h6' id='modal-title' className='modal-title'>
              Payment Succeeded
            </Typography>
          </Grid>
        </Grid>
        <Grid container alignItems='center' justifyContent='center' className='center'>
          <Grid item xs={12}>
            <Typography component='h2' className='section-title'>
              Payment Info
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography component='p' className='section-item'>
              Total
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography component='p' className='section-value'>
              {props.transaction.currency.symbol} {parseFloat(props.transaction.total).toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography component='p' className='section-item'>
              Amount received
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography component='p' className='section-value'>
              {props.transaction.currency.symbol} {parseFloat(props.transaction.paid).toFixed(2)}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography component='p' className='section-item'>
              Change due
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography component='p' className='section-value'>
              {props.transaction.currency.symbol}{' '}
              {parseFloat(props.transaction.paid - props.transaction.total).toFixed(2)}
            </Typography>
          </Grid>
        </Grid>
        <Grid container alignItems='center' justifyContent='center' className='center'>
          <Grid item xs={12}>
            <Typography component='h2' className='section-title'>
              Send receipt
            </Typography>
          </Grid>
          <Grid item xs={8} className='no-right'>
            <TextField
              id='customer'
              label='Customer'
              value={props.customer ? props.customer : customer}
              onChange={e => setCustomer(e.target.value)}
              margin='normal'
              type='email'
            />
          </Grid>
          <Grid item xs={4} className='btn-top'>
            <Button
              variant='contained'
              style={{
                color: '#f0f0f0',
                backgroundColor: '#318ed5',
                '&:hover, &:focus, &:active': {
                  backgroundColor: '#318ed5'
                }
              }}
              onClick={sendEmail}
            >
              Send email
            </Button>
          </Grid>
        </Grid>
      </div>
    </Modal>
  )
}

export default Resume
