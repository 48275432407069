import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Route, Redirect, withRouter, useLocation } from 'react-router-dom'

import Login from './components/Login'
import Main from './components/Main'
import DeviceRegistration from './components/DeviceRegistration'

import AppBar from '@material-ui/core/AppBar'
import Typography from '@material-ui/core/Typography'

import FullscreenListener from './utils/FullscreenListener'
import { AppInstances } from './utils/counterSdkInstance'
import ErrorUtils from './utils/ErrorUtils'
import countrLogo from './assets/countr_white.png'
import './App.css'

const mapStateToProps = state => {
  return {
    user: state.user,
    device: state.device,
    storeLogo: state.device.device.store
  }
}

const App = props => {
  const location = useLocation()
  const [fullScreenApp, setFullScreenApp] = useState(false)
  const [displayLogo, setDisplayLogo] = useState(countrLogo)
  const [altImgName, setAltImgName] = useState('Countrhq logo')

  const handleOpenFullScreen = () => {
    if (location.pathname === '/main' || location.pathname === '/registration') {
      setFullScreenApp(true)
    }
  }

  const handleCloseFullScreen = () => {
    setFullScreenApp(false)
  }

  const isAuth = () => {
    let result = false
    if (props.user.user._id) {
      result = true
    }
    return result
  }

  const hasDeviceandUser = () => {
    let result = false
    if (isAuth() && props.device.listeningDevice.length) {
      result = true
    }
    return result
  }

  const handleShowUserLogo = async store => {
    try {
      const countr = await AppInstances.getCountrSdk()
      const selectedStore = await countr.stores.readOne(store)

      if (!!selectedStore?.store_logo?.length) {
        setDisplayLogo(selectedStore.store_logo)
        setAltImgName(selectedStore?.name || 'Merchant logo')
      }
    } catch (error) {
     ErrorUtils.logErrorToCountr(error)
    }
  }

  useEffect(() => {
    if (props.storeLogo?.length) {
      handleShowUserLogo(props.storeLogo)
    }
  }, [props.storeLogo])

  return (
    <div>
      {fullScreenApp && (
        <FullscreenListener
          handleCloseFullScreen={handleCloseFullScreen}
          fullScreenApp={fullScreenApp}
        />
      )}

      <AppBar position='fixed' className='app-bar'>
        <Typography type='h6' color='inherit' onClick={handleOpenFullScreen}>
          <img alt={altImgName} className='logoImg' src={displayLogo} />
        </Typography>
      </AppBar>

      <Route exact path='/' render={({ history }) => <Login history={history} />} />
      <Route
        path='/main'
        render={({ history }) =>
          hasDeviceandUser() ? <Main history={history} /> : <Redirect to='/' />
        }
      />
      <Route
        path='/registration'
        render={({ history }) =>
          isAuth() ? <DeviceRegistration history={history} /> : <Redirect to='/' />
        }
      />
    </div>
  )
}

const AppConnected = connect(mapStateToProps, null)(App)
export default withRouter(AppConnected)
