// import { CLIENT_ID, CLIENT_SECRET, API_SERVER } from 'react-native-dotenv';
// import { localStorage } from 'react-native';
import CountrClient from '@countr/sdk-convious'

/**
 * Creating a instance for Countr SDK
 */
export const AppInstances = (function () {
  var instance

  async function createCountrSdk() {
    // console.log('****** CREATED COUNTR SDK ******')
    const countrClient = new CountrClient({
      opts: {
        staging: process.env.REACT_APP_STAGING === 'true',
        enableSocket: true,
        reconnect: true
      }
    })

    countrClient.setClient({
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET
    })

    countrClient.on('refreshed', async token => {
      // console.log('****** REFRESHED TOKEN ******', token)
      try {
        localStorage.setItem('access_token', token.access_token)
        localStorage.setItem('refresh_token', token.refresh_token)
      } catch (error) {
        console.error(error)
      }
    })

    let reconnectInterval

    if(countrClient.ws) {
      countrClient.ws.socket.on('connect', () => {
        console.log('Socket connected')
        reconnectInterval && clearInterval(reconnectInterval)
      })

      countrClient.ws.socket.on('error', (e) => {
        console.log('Socket error', e)
      })

      countrClient.ws.socket.on('connect_error', (e) => {
        console.log('Socket connect error', e)
      })

      countrClient.ws.socket.on('disconnect', () => {
        console.log('Socket disconnected')
        reconnectInterval = setInterval(function() {
          if(countrClient.ws.socket) countrClient.ws.socket.connect()
        }, 3000)
      })
    }



    return countrClient
  }

  return {
    getCountrSdk: function () {
      if (!instance) {
        // console.log('****** CREATING NEW INSTANCE FOR SDK ******')
        instance = createCountrSdk()
      }

      // console.log('****** RETURNING INSTANCE FOR SDK ******')
      return instance
    },
    /**
     * Log error with Countr
     */
     logError: async errorObj => {
      const countr = await instance

      errorObj.date = new Date().toISOString()
      errorObj.source = process.env.REACT_APP_ERROR_SOURCE

      if (errorObj.user instanceof Object && !!errorObj.user._id) {
        errorObj.user = errorObj.user._id
      }

      try {
        countr.e.create(errorObj).catch(error => {
          console.log(error)
        })
      } catch (error) {
        console.log(error)
      }
    }
  }
})()
