import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import { AppInstances } from '../../utils/counterSdkInstance'
import StoreList from './StoresList'
import CountrLoader from '../../utils/CountrLoader'
import { addListeningDevice, addDevice } from '../../store/actions/device'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'
import { Alert, AlertTitle } from '@material-ui/lab'

import '../Login/Login.css'

const mapDispatchToProps = dispatch => ({
  addListeningDevice: id => dispatch(addListeningDevice(id)),
  addDevice: device => dispatch(addDevice(device))
})

const mapStateToProps = state => {
  return {
    device: state.device,
    user: state.user,
    settings: state.settings
  }
}

const DeviceRegistration = props => {
  const [deviceName, setDeviceName] = useState('')
  const [storeSelected, setStoreSelected] = useState('')
  const [deviceSelected, setDeviceSelected] = useState('')
  const [loadStores, setLoadStores] = useState(false)
  const [pageLoading, setPageLoading] = useState(true)
  const [error, setError] = useState('')

  const init = () => {
    setDeviceName(props.device.device.name)
    setStoreSelected(props.device.device.store)
    setLoadStores(true)
  }

  const handleCloseLoading = () => {
    setPageLoading(false)
  }

  const handlerStoreSelect = store => {
    setStoreSelected(store)
  }

  const handlerDeviceSelect = device => {
    setDeviceSelected(device)
  }

  const submit = async () => {
    try {
      const user = props.user.user

      const param = {
        store: storeSelected,
        name: deviceName,
        uuid: props.device.device.uuid,
        deviceSelected: deviceSelected,
        merchant: user._id,
        settings: props.settings
      }

      const countr = await AppInstances.getCountrSdk()
      const updatedDevice = props.device?.device?._id
        ? await countr.devices.update(props.device.device._id, param)
        : await countr.devices.create(JSON.stringify(param))

      localStorage.setItem('cds_device_id', updatedDevice._id)
      localStorage.setItem('device', deviceSelected)
      
      props.addDevice(updatedDevice)
      props.addListeningDevice(deviceSelected)

      if (props.modal && props.handleClose) {
        props.handleClose()
        window.location.reload()
      } else {
        props.history.push('/main')
      }
    } catch (error) {
      const errorMsg = JSON.parse(error.message)
      setError(
        Object.keys(errorMsg)
          .map(key => errorMsg[key])
          .join(', ')
      )
    }
  }

  const closeNotification = () => {
    setError('')
  }

  const handleSiabledButton = () => {
    let result = false
    if (!deviceName?.length || !storeSelected?.length || !deviceSelected?.length) {
      result = true
    }
    return result
  }

  useEffect(() => {
    setPageLoading(true)
    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {pageLoading && <CountrLoader action={'Checking device'} />}
      <div className={props.classes.deviceRegistration} style={props.modal ? { marginTop: 0 } : {}}>
        <div className='card-center'>
          <Grid container className={props.classes.root}>
            <Grid item xs={12} className={props.classes.grid}>
              <TextField
                id='deviceName'
                label='Device Name'
                className={props.classes.textField}
                value={deviceName}
                onChange={e => setDeviceName(e.target.value)}
                margin='normal'
              />
              {loadStores && (
                <StoreList
                  history={props.history}
                  storeSelected={storeSelected}
                  handlerStoreSelect={handlerStoreSelect}
                  handlerDeviceSelect={handlerDeviceSelect}
                  handleCloseLoading={handleCloseLoading}
                  device={props.device.device}
                  user={props.user.user}
                  listeningDevice={props.device.listeningDevice}
                  modal={props.modal}
                />
              )}
              <Button
                variant='contained'
                onClick={submit}
                className={props.classes.registrationBtn}
                disabled={handleSiabledButton()}
              >
                {props.modal ? 'Update' : 'Start'}
              </Button>
            </Grid>
          </Grid>
        </div>
        <Snackbar open={!!error} autoHideDuration={5000} onClick={closeNotification}>
          <Alert variant='filled' severity='error' onClose={closeNotification}>
            <AlertTitle>Registration error</AlertTitle>
            {error}
          </Alert>
        </Snackbar>
      </div>
    </>
  )
}

const styles = {
  deviceRegistration: {
    marginTop: 65,
    padding: 15
  },
  root: {
    flexGrow: 1,
    textAlign: 'center',
    justify: 'center',
    alignItems: 'center'
  },
  grid: {
    direction: 'column',
    justify: 'center',
    alignItems: 'center'
  },
  textField: {
    width: 300
  },
  registrationBtn: {
    width: 300,
    color: '#FFF',
    backgroundColor: '#318ed5',
    '&:hover, &:focus, &:active': {
      backgroundColor: '#318ed5'
    }
  }
}
const DeviceRegistrationConnected = connect(mapStateToProps, mapDispatchToProps)(DeviceRegistration)
export default withStyles(styles)(DeviceRegistrationConnected)
